<template>
    <gmaps-map v-if="mapOptions" class="screen-height" :options="mapOptions" style="" @mounted="ready">
        <gmaps-circle v-if="markerPos" :center="markerPos" :radius="radius" strokeColor="red" fillColor="red" clickable
            editable z-index=-1 />
        <gmaps-marker v-for="(m, i) in markers" :key="i" :options="m" />
    </gmaps-map>
</template>

<script>
import {
    externalData
} from '@/src/state/api/pwaexternal';
import {
    gmapsMap,
    gmapsMarker,
    gmapsCircle
} from 'x5-gmaps'

export default {
    components: {
        gmapsMap,
        gmapsMarker,
        gmapsCircle
    },
    data() {
        return {
            addressPlace: {},
            places: null,
            map: null,
            markerPos: null,
            mapOptions: {
                center: {
                    lat: -27.384687,
                    lng: 152.4671002
                },
                zoom: 16,
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
            },
            radius: 200,
            markers: [],
        }
    },
    computed: {
        locationId() {
            return this.$route.params.locationId;
        },
        designationId() {
            return this.$route.params.designationId;
        },
        categoryId() {
            return this.$route.params.categoryId;
        },
    },
    mounted() {
        this.scrollToElement();
    },
    async created() {
        await this.loadMapData();
        await this.getLocation();
    },
    methods: {
        getLocation() {
            if (!("geolocation" in navigator)) {
                this.errorStr = 'Geolocation is not available.';
                return;
            }

            // get position
            navigator.geolocation.getCurrentPosition(pos => {
                const position = {
                    lat: pos.coords.latitude,
                    lng: pos.coords.longitude,
                };
                this.markers.push({
                    position,
                    //icon: "https://storagetawcs.blob.core.windows.net/emailcdn/yellow-pin.png"
                    label: "Current Position"
                })

            }, err => {
                console.log(err);
            })
        },
        scrollToElement() {
            const el = this.$refs.scrollToMe;
            if (el) {
                el.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        },
        async loadMapData() {
            var list = await externalData.searchMapByLocation(this.locationId);
            if (list.length <= 0) {
                //this.address = `${this.currentUser.locationAddress}`;
                this.loadFromAddress();
                return;
            } else {
                //this.address = `${this.currentUser.locationAddress}`;
            }

            this.markers = [];
            list.forEach((item) => {
                if (item.type === "CIRCLE") {
                    this.radius = item.radius;
                    this.markerPos = {
                        lat: item.lattitude,
                        lng: item.longitude
                    }
                    this.mapOptions = {
                        center: {
                            lat: item.lattitude,
                            lng: item.longitude
                        },
                        zoom: 16
                    }
                } else if (item.type === "MARKER") {
                    const position = {
                        lat: item.lattitude,
                        lng: item.longitude
                    };
                    this.markers.push({
                        position,
                        icon: "https://storagetawcs.blob.core.windows.net/emailcdn/yellow-pin.png",
                        animation: this.bounce,
                        label: "Assembly Area"
                    })
                }
            })
        },
        ready(map) {
            this.map = map
            this.$GMaps().then((maps) => {
                this.places = new maps.places.PlacesService(map)
                this.autocomplete = new maps.places.Autocomplete(this.$refs.autocomplete)
                this.autocomplete.addListener('place_changed', this.update)
                this.loadFromAddress();
            })
        },
        loadFromAddress() {
            var _this = this;
            var geocoder = new google.maps.Geocoder;
            geocoder.geocode({
                'address': this.address
            }, function (results, status) {
                if (status === 'OK') {
                    _this.setMapLocationCallback.call(_this, results, status);
                }
            })
        },
        setMapLocationCallback: function (results, status) {
            this.addressPlace = results[0];
            if (this.addressPlace.geometry) {
                this.markerPos = this.addressPlace.geometry.location;
                this.mapOptions = {
                    center: this.addressPlace.geometry.location,
                    zoom: 16
                }
            }
        },
        update() {
            const place = this.autocomplete.getPlace()
            if (place.geometry) {
                // this.map.panTo(place.geometry.location)
                this.markerPos = place.geometry.location
                this.mapOptions = {
                    center: place.geometry.location,
                    zoom: 16
                }
            }
        },
        addMarker($event) {
            // Stop last marker from bouncing
            // if (this.markers.length) this.markers[this.markers.length - 1].animation = null
            // Add new marker with a bounce            
            const position = $event.latLng.toJSON()
            this.markers.push({
                position,
                icon: "https://storagetawcs.blob.core.windows.net/emailcdn/yellow-pin.png",
                animation: this.bounce
            })
        },
        removeMarker(index) {
            this.markers.splice(index, 1)
        }
    }
}
</script>

<style lang="scss" scoped>
.screen-height {
    width: 100%;
    height: calc(100vh - 135px);
    overflow: hidden;
}
</style>
